import React from "react";
import { Box, Typography, Link, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AppDetails } from "../../utils/enums";
import { colors } from "../../resources/colors";
import { whiteBriniti } from '../../library/resources/images';

const styles = {
  container: {
    background:
      "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(88, 177, 173, 0.5) 60%, rgba(22, 84, 99, 0.9) 100%)",
    color: colors.white,
    width: "100%",
    padding: { xs: "0px 16px", md: "0px 160px" },
  },
  topSection: {
    paddingBottom: "16px",
  },
  bottomSection: {
    // background: colors.darkGreen,
    padding: { xs: "16px 0px" },
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: { xs: "column", sm: "row" },
  },
  logo: {
    width: { xs: "60%", sm: "50%" },
    marginBottom: "24px",
  },
  contactTitle: {
    fontSize: "1rem",
    textTransform: "uppercase",
    fontWeight: 500,
    fontFamily: "Inter",
    marginBottom: "16px",
  },
  contactText: {
    fontSize: "1.2rem",
    fontWeight: 600,
    fontFamily: "Inter",
    marginBottom: "8px",
  },
  contactPhone: {
    fontSize: "1.2rem",
    fontWeight: 400,
    fontFamily: "Inter",
    color: "inherit",
    textDecoration:'none'
  },
  contactLink: {
    textDecoration: "underline",
    fontSize: "1.2rem",
    fontWeight: 400,
    fontFamily: "Inter",
    color: "inherit",
  },
  socialLink: {
    color: "white",
    textDecoration: "none",
    border: "1px solid rgba(255, 255, 255, 0.25)",
    padding: "8px 15px",
    borderRadius: "40px",
    fontSize: "1.1rem",
    textAlign: "center",
    fontFamily: "Inter",
  },
  rightsText: {
    color: "white",
    textTransform: "uppercase",
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "1rem",
  },
};

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Box sx={styles.container}>
      {/* Top Section */}
      <Box sx={styles.topSection}>
        <Grid container spacing={4} alignItems="stretch">
          {/* Contact Section */}
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{ display: "flex", flexDirection: "column", height: "100%" }}
            >
              <Box>
                <Box
                  component="img"
                  src={whiteBriniti.src}
                  alt="WhiteBriniti"
                  sx={styles.logo}
                />
                <Typography variant="subtitle1" sx={styles.contactTitle}>
                  {t("footer.contact")}
                </Typography>
                <Typography sx={styles.contactText}>
                  {AppDetails.address}
                </Typography>
                <Typography sx={styles.contactText}>
                <Link
                  href={`tel:${AppDetails.phoneNumber}`}
                  sx={styles.contactPhone}
                >
                  {AppDetails.phoneDisplayNumber}
                  </Link>
                  </Typography>
                <Link
                  href={`mailto:${AppDetails.email}`}
                  sx={styles.contactLink}
                >
                  {AppDetails.email}
                </Link>
              </Box>
            </Box>
          </Grid>

          {/* Social Links Section */}
          <Grid item xs={12} sm={6} md={8}>
            <Box
              sx={{
                display: "flex",
                justifyContent: { xs: "center", sm: "flex-start" },
                alignItems: "flex-end",
                height: "100%",
              }}
            >
              <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap" }}>
                <Link
                  href={AppDetails.instagram}
                  target="_blank"
                  sx={styles.socialLink}
                >
                  {t("footer.networks.instagram")}
                </Link>
                <Link
                  href={AppDetails.tikTok}
                  target="_blank"
                  sx={styles.socialLink}
                >
                  {t("footer.networks.tikTok")}
                </Link>
                <Link
                  href={AppDetails.youtube}
                  target="_blank"
                  sx={styles.socialLink}
                >
                  {t("footer.networks.youtube")}
                </Link>
                <Link
                  href={AppDetails.linkedin}
                  target="_blank"
                  sx={styles.socialLink}
                >
                  {t("footer.networks.linkedin")}
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Bottom Section */}
      <Box sx={styles.bottomSection}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Box
            component="img"
            src={whiteBriniti.src}
            alt="AllRightsBriniti"
            sx={{ height: "1rem", marginTop: "-5px" }}
          />
          <Typography variant="body2" sx={styles.rightsText}>
            {t("footer.allRights")}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            flexWrap: "wrap",
            justifyContent: "center",
            mt: { xs: 2, sm: 0 },
          }}
        >
          <Link
            href="privacy-policy"
            sx={{
              ...styles.contactLink,
              textTransform: "uppercase",
              fontSize: "1rem",
              fontWeight: 500,
            }}
          >
            {t("footer.privacyPolicy")}
          </Link>
          <Box
            sx={{
              width: "0.5px",
              height: "24px",
              backgroundColor: "white",
              margin: "0 2px",
              opacity:'1'
            }}
          />
          <Link
            href="terms-of-use"
            sx={{
              ...styles.contactLink,
              textTransform: "uppercase",
              fontSize: "1rem",
              fontWeight: 500,
            }}
          >
            {t("footer.termsCondition")}
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;

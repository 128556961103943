import React from 'react'
import { useParams } from 'react-router-dom';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import Spacer from '../../infrastructure/components/Spacer';
import { authService } from '../../library/services/authService';

import { whiteBriniti } from '../../library/resources/images';
import { Box } from '@mui/material';


const ActivateUser = () => {

  let { uid, token } = useParams()

  React.useEffect(() => {
    const callAuthService = async () => {
      try {
        await authService.activateAccount(uid, token)
      }
      catch (error) {

      }
    }
    callAuthService()
  }, [])


  return (
    <Container component='main' sx={{
      maxWidth:"100vw !important",
      height:'100vh',
      background:{
          xs:'linear-gradient(145deg, rgba(255,255,255,1) 15%, rgba(88,177,173,1) 48%, rgba(22,84,99,1) 100%);',
          md:'linear-gradient(171.22deg, #FFFFFF 3.42%, #58B1AD 43.47%, #165463 102.53%)'
      },
      display:'flex',
      flexDirection:'column',
      justifyContent:'center',
      alignItems:'center',
      gap:'20px'}}>
      <Spacer sx={{height:"3rem"}}></Spacer>
      <Box component="img" src={whiteBriniti.src} sx={{width:{xs:"60vw", sm:'40vw', md:'300px', lg:"300px"}}}></Box>
      <Typography component='h1' className='text-center' sx={{color:"#fff", textAlign:'center', typography:{xs:'h4', sm:'h2'}}}>
        Dobrodošli na Briniti
      </Typography>
      <Typography component='h1' variant='h4' className='text-center pt-3' sx={{color:"#fff", textAlign:'center', typography:{xs:'h5', sm:'h4'}}}>
        Uspešno ste aktivirali nalog
      </Typography>
      <Typography component='h1' variant='h6' className='text-center pt-3' sx={{color:"#fff", textAlign:'center', typography:{xs:'body1', sm:'h6'}}}>
        Nastavite sa korišćenjem mobilne aplikacije
      </Typography>
    </Container>
  )
}

export default ActivateUser

import { Box, Typography } from "@mui/material";
import ReactPlayer from "react-player";
import { colors } from "../../resources/colors";

interface VideoSegmentProps {
  title: string;
  videoUrl: string;
  playerWidth?: number | string;
  playerHeight?: number | string;
}

const VideoSegment: React.FC<VideoSegmentProps> = (props) => {
  const { title, videoUrl, playerWidth, playerHeight } = props;

  return (
    <Box sx={styles.mainBox}>
      <Typography sx={styles.title}>{title}</Typography>
      <Box sx={styles.videoContainer}>
        <Box sx={styles.videoWrapper}>
          <ReactPlayer
            width={playerWidth ?? "100%"}
            height={playerHeight ?? "100%"}
            url={videoUrl}
            controls
            wrapper={Box}
          />
        </Box>
      </Box>
    </Box>
  );
};

const styles = {
  mainBox: {
    marginTop: "2rem",
  },
  title: {
    fontFamily: "Inter",
    fontSize: { xs: "2rem", md: "3rem" },
    lineHeight: { xs: "2.5rem", md: "3.5rem" },
    fontWeight: "800",
    color: colors.lightGreen,
    textAlign: "center",
    marginBottom: "2rem",
  },
  descriptionBox: {
    width: "100%",
    marginTop: { xs: "1.5rem", md: "3rem" },
  },
  videoContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    my: { xs: 1, md: 1.5 },
    width: "100%",
  },
  videoWrapper: {
    width: { xs: "100%", md: "80%" },
    aspectRatio: "16/9",
    borderRadius: "15px",
    overflow: "hidden",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
  },
};

export default VideoSegment;

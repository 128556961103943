import axios from 'axios'
import humps from 'humps'

import { API_EP} from '../../config'

import { authErrorHandler, errorHandler } from './errorHandler'
import dataTransformer from './dataTransformer'
import { storage, KEYS } from '../storage'

class ApiService {
    http = axios.create({
        baseURL: API_EP || undefined,
        timeout: 30000,
        // transformResponse: [dataTransformer]
    })

    httpService = axios

    constructor() {
        this.http.interceptors.request.use(config => {
            if (config.url && !config.url.endsWith('/')) {
                config.url += '/'
            }
            return config
        })
    }


    async get(path: string, config?: {}) {
        const options = {
            headers: {
                'Content-Type': 'application/json',
            },
            ...config
        }

        return this.http
            .get(path, options)
            .then(response => response.data)
            .catch(authErrorHandler)
            .catch(errorHandler)
    }

    /**
     * Exposes original axios response without transformation
     * @param path Endpoint path
     * @param config AxiosConfig object { params, headers }
     */
    async GET(path: string, config?: {}) {
        const options = {
            headers: {
                'Content-Type': 'application/json',
            },
            ...config
        }

        const requestUrl = `${API_EP}${path}`
        return this.httpService.get(requestUrl, options).catch(authErrorHandler)
    }

    async post(path: string, payload: {}, config?: any) {
        const options = {
            headers: {
                'Content-Type': 'application/json',
            },
            ...config
        }
        return this.http
            .post(path, humps.decamelizeKeys(payload), options)
    }

    async put(path: string, payload: {}, config?: any) {
        const options = {
            headers: {
                'Content-Type': 'application/json',
            },
            ...config
        }
        return this.http
            .put(path, humps.decamelizeKeys(payload), options)
            .catch(authErrorHandler)
            .catch(errorHandler)
    }

    async delete(path: string, config?: any) {
        const options = {
            headers: {
                'Content-Type': 'application/json',
            },
            ...config
        }
        return this.http
            .delete(path,options)
            .catch(authErrorHandler)
            .catch(errorHandler)
    }
}

export const API = new ApiService()

import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Skeleton,
  Fade,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  firstPhone,
  firstPhoneMobile,
  secondPhone,
  secondPhoneMobile,
  thirdPhone,
  thirdPhoneMobile,
  fourthPhoneMobile,
  whiteBriniti,
} from "../../library/resources/images";

const InitialSection: React.FC = () => {
  const { t } = useTranslation();
  const images = [firstPhone.src, secondPhone.src, thirdPhone.src];
  const imagesMobile = [ firstPhoneMobile.src, secondPhoneMobile.src, fourthPhoneMobile.src];
  const [currentImage, setCurrentImage] = useState(0);
  const [loadedImages, setLoadedImages] = useState<boolean[]>(
    new Array(images.length).fill(false)
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmallTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isBigTablet = useMediaQuery(theme.breakpoints.between('md', 'lg'));

  useEffect(() => {
    if (currentImage < images.length) {
      const timer = setTimeout(() => {
        setCurrentImage((prev) => prev + 1);
      }, 800);
      return () => clearTimeout(timer);
    }
  }, [currentImage, images.length]);

  const handleImageLoad = (index: number) => {
    const newLoadedImages = [...loadedImages];
    newLoadedImages[index] = true;
    setLoadedImages(newLoadedImages);
  };

  return (
    <Box
      sx={{
        background:
          "linear-gradient(180deg, #33C0DB -10.86%, #378288 54.66%, #165463 147.03%)",
        color: "white",
        display: "flex",
        flexDirection: { xs: "column", lg: "row" },
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        paddingX: { xs: 2, lg: 20 },
        paddingY: { xs: 15, lg: 5 },
        minHeight: {xs:'100vh', sm:'115vh', md:'112vh', lg:'100vh'},
        paddingBottom:"0 !important"
      }}
    >
      {/* Text Section */}
      <Box
        sx={{
          flex: 1,
          textAlign: { xs: "left", md: "left" },
          marginBottom: { xs: "2.75rem", lg: 0 },
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{
            fontSize: { xs: "1.2rem", lg: "1.4rem" },
            lineHeight: "1.6",
            marginBottom: "1rem",
            fontWeight: 300,
            fontFamily: "Montserrat, sans-serif",
          }}
        >
          {t("initial.title01")}
        </Typography>

        <Box
          component="img"
          src={whiteBriniti.src}
          alt="Briniti Logo"
          sx={{
            width: { xs: "90%", lg: "200px" },
            marginBottom: "1.5rem",
          }}
        />

        <Typography
          variant="subtitle1"
          sx={{
            fontSize: { xs: "1.4rem", lg: "1.8rem" },
            lineHeight: { xs: "1.8rem", lg: "2rem" },
            fontFamily: "Montserrat, sans-serif",
            fontWeight: 600,
          }}
        >
          {t("initial.title02")}
          <Box
            component="span"
            sx={{
              fontWeight: 300,
            }}
          >
            {" "}
            - {t("initial.title03")}
          </Box>
        </Typography>
      </Box>

      {/* Image Section */}
      {!isMobile && !isSmallTablet && !isBigTablet && (
        <Box
          sx={{
            flex: 2,
            display: "flex",
            justifyContent: { xs: "center", md: "flex-end" },
            alignItems: "center",
            gap: { xs: "1rem", md: "2rem" },
            flexWrap: { xs: "wrap", md: "nowrap" },
          }}
        >
          {!isMobile && !isSmallTablet && !isBigTablet &&
            images.map((src, index) => (
              <Fade key={index} in={currentImage >= index} timeout={800}>
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 600,
                    marginTop: index === 1 ? "3.5rem" : "10rem",
                  }}
                >
                  {!loadedImages[index] && (
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height="100%"
                      sx={{
                        borderRadius: 2,
                      }}
                    />
                  )}
                  <Box
                    component="img"
                    src={src}
                    alt={`Phone ${index + 1}`}
                    sx={{
                      display: loadedImages[index] ? "block" : "none",
                      transition: "opacity 0.5s ease-in-out",
                      opacity: loadedImages[index] ? 1 : 0,
                      borderRadius: 2,
                      width: "100%",
                      height: "100%",    
                      objectFit: "cover",
                    }}
                    onLoad={() => handleImageLoad(index)}
                  />
                </Box>
              </Fade>
            ))}
        </Box>
      )}
      {isSmallTablet && !isBigTablet && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "flex-end",
            gap: { xs: "0rem", sm: "1rem", md: "3rem" },
            width: "100%",
            minHeight:'390px'
          }}
        >
          {/* Left img */}
          <Fade in={currentImage >= 1} timeout={800}>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position:'relative',
                minHeight:'390px',
                '& > *:first-child':{
                  position:'absolute',
                  top:"-135px",
                  left: "50%",
                  transform: "translateX(-50%)",
                }
              }}
            >
              <Box
                component="img"
                src={imagesMobile[1]}
                alt="Left Image"
                sx={{
                  width: { xs: "180px", sm: "280px", md: "380px" },
                  maxWidth: "450px",
                  borderRadius: 2,
                }}
              />
            </Box>
          </Fade>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              position:'relative',
              '& > *:first-child':{
                position:'absolute',
                top:"-480px",
                left: "50%",
                transform: "translateX(-50%)",
              }
            }}
          >
            {imagesMobile.map((src, index) => (
              (index != 1) && (
              <Fade in={currentImage >= index} timeout={800}>
                <Box
                  key={index}
                  component="img"
                  src={src}
                  sx={{
                    width: { xs: "180px", sm: "280px", md: "380px" },
                    maxWidth: "450px",
                    borderRadius: 2,
                  }}
                />
              </Fade>
              )
            ))}
          </Box>
        </Box>
      )}
      {isBigTablet && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "flex-end",
            gap: { xs: "0rem", sm: "1rem", md: "3rem" },
            width: "100%",
            minHeight:'390px',
          }}
        >
          {/* Left img */}
          <Fade in={currentImage >= 1} timeout={800}>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position:'relative',
                minHeight:'390px',
                '& > *:first-child':{
                  position:'absolute',
                  top:"-335px",
                  left: "50%",
                  transform: "translateX(-50%)",
                }
              }}
            >
              <Box
                component="img"
                src={imagesMobile[1]}
                alt="Left Image"
                sx={{
                  width: { xs: "180px", sm: "280px", md: "380px" },
                  maxWidth: "450px",
                  borderRadius: 2,
                }}
              />
            </Box>
          </Fade>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              position:'relative',
              '& > *:first-child':{
                position:'absolute',
                top:"-680px",
                left: "50%",
                transform: "translateX(-50%)",
              }
            }}
          >
            {imagesMobile.map((src, index) => (
              (index != 1) && (
              <Fade in={currentImage >= index} timeout={800}>
                <Box
                  key={index}
                  component="img"
                  src={src}
                  sx={{
                    width: { xs: "180px", sm: "280px", md: "380px" },
                    maxWidth: "450px",
                    borderRadius: 2,
                  }}
                />
              </Fade>
              )
            ))}
          </Box>
        </Box>
      )}
      {isMobile && !isSmallTablet && !isBigTablet && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "flex-end",
            gap: { xs: "0rem", sm: "1rem", md: "3rem" },
            width: "100%",
            minHeight:'390px'
          }}
        >
          {/* Left Image */}
          <Fade in={currentImage >= 1} timeout={800}>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position:'relative',
                minHeight:'390px',
                '& > *:first-child':{
                  position:'absolute',
                  top:"65px",
                  left: "50%",
                  transform: "translateX(-50%)",
                }
              }}
            >
              <Box
                component="img"
                src={imagesMobile[1]}
                alt="Left Image"
                sx={{
                  width: { xs: "180px", sm: "280px", md: "380px" },
                  maxWidth: "450px",
                  borderRadius: 2,
                }}
              />
            </Box>
          </Fade>
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              position:'relative',
              '& > *:first-child':{
                position:'absolute',
                top:"-300px",
                left: "50%",
                transform: "translateX(-50%)",
              }
            }}
          >
            {imagesMobile.map((src, index) => (
              (index != 1) && (
              <Fade in={currentImage >= index} timeout={800}>
                <Box
                  key={index}
                  component="img"
                  src={src}
                  sx={{
                    width: { xs: "180px", sm: "280px", md: "380px" },
                    maxWidth: "450px",
                    borderRadius: 2,
                  }}
                />
              </Fade>
              )
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default InitialSection;

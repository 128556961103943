import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Spacer from "../infrastructure/components/Spacer";

import { logo } from "../library/resources/images";
import LanguageSwitcher from "../i18n/LanguageSwitcher";
import { Trans, useTranslation } from "react-i18next";
import { List, ListItem } from "@mui/material";

const dataCollectionTimeBulletArray1 = [
  "privacyPolicy.whenWeCollectYourData.firstLastName",
  "privacyPolicy.whenWeCollectYourData.password",
  "privacyPolicy.whenWeCollectYourData.emailAddress",
];

const dataCollectionTimeBulletArray2 = [
  "privacyPolicy.whenWeCollectYourData.dateOfBirth",
  "privacyPolicy.whenWeCollectYourData.contactEmail",
];

const userRightsBulletArray = [
  "privacyPolicy.userRights.bulletList.personalDataAccess",
  "privacyPolicy.userRights.bulletList.personalDataCorrection",
  "privacyPolicy.userRights.bulletList.personalDataDeletion",
  "privacyPolicy.userRights.bulletList.personalDataPortability",
  "privacyPolicy.userRights.bulletList.personalDataProcessingRestriction",
  "privacyPolicy.userRights.bulletList.rightToObject",
  "privacyPolicy.userRights.bulletList.automatedIndividualDecisionMakingRights",
];

const PrivacyPage = () => {
  const { t } = useTranslation();
  return (
    <Container component="main" maxWidth="md">
      <Box sx={{ display: "flex", justifyContent: "flex-end", paddingY: 2 }}>
        <LanguageSwitcher defaultLanguage={"en"} />
      </Box>
      <Spacer height={"2rem"} />
      <Link href="/" sx={{ display: "flex", justifyContent: "center" }}>
        <Box component="img" src={logo.src}></Box>
      </Link>
      <Spacer height={"4rem"} />

      <Typography variant="h3" sx={styles.pageTitle}>
        {t("privacyPolicy.title").toUpperCase()}
      </Typography>
      <Spacer height={"4rem"} />
      <Typography variant="h5" sx={styles.title}>
        {t("privacyPolicy.introduction.title")}
      </Typography>
      <Typography textAlign="justify">
        {t("privacyPolicy.introduction.text")}
      </Typography>

      <Typography variant="h5" sx={styles.title}>
        {`1. ${t("privacyPolicy.dataController.title")}`}
      </Typography>
      <Typography sx={styles.text}>
        {t("privacyPolicy.dataController.text")}
      </Typography>

      <Typography variant="h5" sx={styles.title}>
        {`2. ${t("privacyPolicy.processingLegalBasis.title")}`}
      </Typography>
      <Typography sx={styles.text}>
        {t("privacyPolicy.processingLegalBasis.text")}
      </Typography>

      <Typography variant="h5" sx={styles.title}>
        {`3. ${t("privacyPolicy.whatPersonalDataWeCollect.title")}`}
      </Typography>
      <Typography sx={styles.whatPersonalDataWeCollectText}>
        <Trans i18nKey="privacyPolicy.whatPersonalDataWeCollect.personalData" />
      </Typography>
      <Typography sx={styles.whatPersonalDataWeCollectText}>
        <Trans i18nKey="privacyPolicy.whatPersonalDataWeCollect.contactData" />
      </Typography>
      <Typography sx={styles.whatPersonalDataWeCollectText}>
        <Trans i18nKey="privacyPolicy.whatPersonalDataWeCollect.usageData" />
      </Typography>
      <Typography sx={styles.whatPersonalDataWeCollectText}>
        <Trans i18nKey="privacyPolicy.whatPersonalDataWeCollect.publiclyDisclosedData" />
      </Typography>
      <Typography sx={styles.whatPersonalDataWeCollectText}>
        <Trans i18nKey="privacyPolicy.whatPersonalDataWeCollect.sensitiveData" />
      </Typography>

      <Typography variant="h5" sx={styles.title}>
        {`4. ${t("privacyPolicy.whenWeCollectYourData.title")}`}
      </Typography>
      <Typography sx={styles.text}>
        {t("privacyPolicy.whenWeCollectYourData.firstParagraph")}
      </Typography>
      <List sx={styles.list}>
        {dataCollectionTimeBulletArray1.map((i18nTag) => (
          <ListItem key={i18nTag} sx={styles.listItem}>
            {t(i18nTag)}
          </ListItem>
        ))}
      </List>
      <Typography sx={styles.text}>
        {t("privacyPolicy.whenWeCollectYourData.secondParagraph")}
      </Typography>
      <List sx={styles.list}>
        {dataCollectionTimeBulletArray2.map((i18nTag) => (
          <ListItem key={i18nTag} sx={styles.listItem}>
            {t(i18nTag)}
          </ListItem>
        ))}
      </List>
      <Typography sx={styles.text}>
        {t("privacyPolicy.whenWeCollectYourData.thirdParagraph")}
      </Typography>

      <Typography variant="h5" sx={styles.title}>
        {`5. ${t("privacyPolicy.communicationWithAppUser.title")}`}
      </Typography>
      <Typography sx={styles.text}>
        {t("privacyPolicy.communicationWithAppUser.text")}
      </Typography>

      <Typography variant="h5" sx={styles.title}>
        {`6. ${t("privacyPolicy.thirdPartyAdvertising.title")}`}
      </Typography>
      <Typography sx={styles.text}>
        {t("privacyPolicy.thirdPartyAdvertising.text")}
      </Typography>

      <Typography variant="h5" sx={styles.title}>
        {`7. ${t("privacyPolicy.personalDataRecipients.title")}`}
      </Typography>
      <Typography sx={styles.text}>
        {t("privacyPolicy.personalDataRecipients.text")}
      </Typography>

      <Typography variant="h5" sx={styles.title}>
        {`8. ${t("privacyPolicy.whereWeStoreYourPersonalData.title")}`}
      </Typography>
      <Typography sx={styles.text}>
        {t("privacyPolicy.whereWeStoreYourPersonalData.text")}
      </Typography>

      <Typography variant="h5" sx={styles.title}>
        {`9. ${t("privacyPolicy.retentionPeriod.title")}`}
      </Typography>
      <Typography sx={styles.text}>
        {t("privacyPolicy.retentionPeriod.text")}
      </Typography>

      <Typography variant="h5" sx={styles.title}>
        {`10. ${t("privacyPolicy.userRights.title")}`}
      </Typography>
      <Typography sx={styles.text}>
        {t("privacyPolicy.userRights.firstParagraph")}
      </Typography>
      <List sx={styles.list}>
        {userRightsBulletArray.map((i18nTag) => (
          <ListItem key={i18nTag} sx={styles.listItem}>
            {t(i18nTag)}
          </ListItem>
        ))}
      </List>
      <Typography sx={styles.text}>
        {t("privacyPolicy.userRights.secondParagraph")}
      </Typography>

      <Typography variant="h5" sx={styles.title}>
        {`11. ${t("privacyPolicy.personalDataSecurity.title")}`}
      </Typography>
      <Typography sx={styles.text}>
        {t("privacyPolicy.personalDataSecurity.text")}
      </Typography>

      <Typography variant="h5" sx={styles.title}>
        {`12. ${t("privacyPolicy.complaints.title")}`}
      </Typography>
      <Typography sx={styles.text}>
        {t("privacyPolicy.complaints.firstParagraph")}
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ ...styles.text, marginY: 2 }}>
          {t("privacyPolicy.complaints.methods.firstMethod")}
        </Typography>
        <Link href="mailto:office@briniti.com" sx={styles.mailToLink}>
          office@briniti.com
        </Link>
      </Box>
      <Typography sx={{ ...styles.text, marginBottom: 2 }}>
        {t("privacyPolicy.complaints.methods.secondMethod")}
      </Typography>
      <Typography sx={styles.text}>
        {t("privacyPolicy.complaints.secondParagraph")}
      </Typography>

      <Spacer height={"4rem"} />
    </Container>
  );
};

const styles = {
  pageTitle: { textAlign: "center", wordWrap: "break-word" },
  title: { fontWeight: "bold", marginY: "1rem" },
  text: { textAlign: "justify" },
  list: { listStyleType: "disc", listStylePosition: "inside" },
  listItem: { display: "list-item" },
  whatPersonalDataWeCollectText: { textAlign: "justify", marginY: "0.5rem" },
  mailToLink: { textDecoration: "none", marginLeft: "0.5rem", lineHeight: 1.5 },
};

export default PrivacyPage;

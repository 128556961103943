import { Box } from "@mui/material";
import Header from "../infrastructure/components/Header";
import Footer from "../infrastructure/components/Footer";
import InitialSection from "../infrastructure/components/InitialSection";
import AboutUsSection from "../infrastructure/components/AboutUsSection";
import EventsForLife from "../infrastructure/components/EventsForLife";
import AboutAppSection from "../infrastructure/components/AboutAppSection";
import { SectionKeys } from '../utils/enums';

const HomePage = () => {
  return (
    <Box>
      <Header />
      <Box sx={{overflowX:"hidden"}}>
        <Box id={SectionKeys.initialSection}>
          <InitialSection />
        </Box>
        <Box id={SectionKeys.aboutUsSection}>
          <AboutUsSection />
        </Box>
        <Box id={SectionKeys.eventsSection}>
          <EventsForLife />
        </Box>
        <Box
          id={SectionKeys.aboutAppSection}
          style={{
            background:
              "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(88, 177, 173, 0.5) 60%, rgba(22, 84, 99, 0.9) 100%)",
          }}
        >
          <AboutAppSection />
          <Footer />
        </Box>
      </Box>
    </Box>
  );
};

export default HomePage;

import { API } from '../api/api'



export class AuthService {

    async activateAccount(uid: any, token: any){
        await API.post(`/auth/users/activation/`, {uid: uid, token: token})
    }
    
    async resetPasswordConfirm(uid: any, token: any, new_password: any){
      const data = await API.post(`/auth/users/reset_password_confirm/`, {uid: uid, token: token, new_password: new_password})

      return data
   
    }

    

}

export const authService = new AuthService()

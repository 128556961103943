import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";

import Spacer from "../infrastructure/components/Spacer";
import { logo } from "../library/resources/images";

const Page404 = () => {
  return (
    <Container component="main" maxWidth="md">
      <Link
        href="/"
        sx={{ display: "flex", justifyContent: "center", padding: "2rem" }}
      >
        <Box component="img" src={logo.src}></Box>
      </Link>
      <Spacer height={"15rem"} />
      <Typography component="h1" variant="h2" className="text-center">
        Tražena stranica ne postoji
      </Typography>
    </Container>
  );
};

export default Page404;

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";

import Spacer from "../infrastructure/components/Spacer";
import { logo } from "../library/resources/images";

const DeleteContactPage = () => {
  return (
    <Container component="main" maxWidth="md">
      <Link
        href="/"
        sx={{ display: "flex", justifyContent: "center", padding: "2rem" }}
      >
        <Box component="img" src={logo.src}></Box>
      </Link>
      <Spacer height={"20vh"} />
      <Typography component="h1" variant="h2" className="text-center">
        Brisanje naloga na Briniti
      </Typography>
      <Typography variant="h6" className="text-center">
        Vaš korisnički nalog možete obrisati kroz aplikaciju ili nas kontaktirajte na <a className="color-1" href="mailto:office@briniti.com">
                      office@briniti.com
                    </a>
      </Typography>
      <Spacer height={"70vh"} />
    </Container>
  );
};

export default DeleteContactPage;

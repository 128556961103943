import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { ParallaxBanner } from 'react-scroll-parallax';
import { useTranslation } from 'react-i18next';
import { colors } from '../../resources/colors';
import { aboutUsLG, aboutUsMD, aboutUsSM, aboutUsXL, parallax } from '../../library/resources/images';

const AboutUsSection: React.FC = () => {
  const {t} = useTranslation();

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));
  const isLg = useMediaQuery(theme.breakpoints.only('lg'));
  const isXl = useMediaQuery(theme.breakpoints.only('xl'));

  const src = 
    isXs ? aboutUsSM.src :
    isSm ? aboutUsSM.src :
    isMd ? aboutUsMD.src :
    isLg ? aboutUsLG.src :
    isXl ? aboutUsXL.src :
    aboutUsMD.src; 
  return (
    <>
      <Box sx={styles.mainBox}>
        <Box sx={styles.titleBox}>
          <Typography sx={styles.aboutUsTypography}>{t("aboutUs.title")}</Typography>
        </Box>
        <Box sx={styles.descriptionBox}>
          <Box component="img" src={src} sx={{ width: "100%" }} />
        </Box>
      </Box>
      <Box sx={styles.parallaxContainer}>
      <ParallaxBanner
        layers={[{ image: parallax.src, speed: 22 }]}
        style={styles.parallaxContainer} 
      />
      </Box>
    </>
  );
};

const styles = {
  mainBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    minHeight: "30vh",
    paddingX: { xs: 2, md: 20 },
    paddingY: { xs: 4, md: 5 },
    alignItems: { xs: "center", md: "flex-start" },
  },
  titleBox: { width: "100%" },
  aboutUsTypography: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: { xs: "1.4rem", md: "1.6rem" },
    lineHeight: { xs: "1.7rem", md: "1.9rem" },
    fontWeight: "300",
    marginLeft:"4px",
    marginBottom:"14px"
  },
  whoWeAreTypography: {
    fontFamily: "Inter",
    fontSize: { xs: "2rem", md: "3rem" },
    lineHeight: { xs: "2.5rem", md: "3.5rem" },
    fontWeight: "800",
    color: colors.lightGreen,
    marginLeft:"4px",
    marginBottom:"12px"
  },
  descriptionBox: { width: "100%" },
  descriptionTypography: {
    fontSize: "2rem",
    fontWeight: "300",
    fontStyle: "italic",
    color: "darkblue",
  },
  parallaxContainer: {
    minHeight: "100vh",
    // backgroundImage: `url(${parallax.src})`,
    // backgroundAttachment: { xs: "scroll", md: "fixed" },
    // backgroundPosition: "center",
    // backgroundRepeat: "no-repeat",
    // backgroundSize: "cover",
    // "@media (max-width: 600px)": {
    //   backgroundAttachment: "scroll",
    // },
  },
};

export default AboutUsSection;

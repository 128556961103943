export const colors = {
    primary: '#484848',
    primaryLight: '#8A8A8A',
    primaryDark: '#CCC',
    bodyText: '#6D6D6D',
    blue: '#73CDC8',
    bgLight: '#F7F7F7',
    white: '#FFFFFF',
    transparent: 'transparent',
    icon: '#E8E8E8',
    iconText: '#666666',
    tabInactive: '#376360',
    imageIcon: '#d3d3d3',
    red: 'red',
    card: '#D8D8D8',
    customCard: '#A9A9A9',
    customIcon: '#4da3ff',
    customIcon2: '#00b300',
    customIcon3: '#4dff4d',
    grey: '#cccccc',
    header1: '#0059b3',
    header2: '#3399ff',
    orange: '#F89157',
    redLight: '#FF7272',
    whiteDark: '#D4D4D4',
    green: '#73CD81',
    black: '#000000',
    darkBlue: '#03a69c',
    institutionCard: '#D0E0DF',
    //test orange variant
    mainOrange: '#E6793B',
    darkRed: '#e35454',
    darkGrey: '#333333',
    coldGrey: '#DEDEDE',
    lightGrey: '#F6F6F6',
    buttonGrey: '#AAAAAA',
    borderGrey: '#888888',
    darkOrange: '#DE5E16',
    headerBackground: '#FF7272',
    headerText: 'FFFFFF',
    newsBackgroundCard: '#F2F2F2',
    iconBackground: '#58B1AD',
    //newColors
    darkGreen: '#165463',
    lightGreen: '#378288',
    businessCommunicatorInfoCard: '#F2F2F7',
    disabledGreen: '#7DAAAE',
    skyBlue: '#23C6ED',
    babyGrey: '#e9e9e9',
    newRed: '#A52929',
  };
  
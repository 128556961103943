import { storage, KEYS } from '../storage'
import Axios from 'axios'
import jwt_decode from 'jwt-decode'


function isTokenExpired(token: any) {
    let decoded = jwt_decode(token) as any
  
    if (decoded.exp < Date.now() / 1000) {
      return true
    } else {
      return false
    }
}

export async function authErrorHandler(err:any){
    try {
        const { status } = err.response
        if([401].includes(status)){
           const refreshToken = await storage.load(KEYS.REFRESH_TOKEN)
           if(!isTokenExpired(refreshToken)){
            const tokenPayload = {
                refresh: await storage.load(KEYS.REFRESH_TOKEN)
            }
            const { data } : any = Axios.post('https://propeler-medic.herokuapp.com/auth/jwt/refresh/', tokenPayload)

            await storage.save(KEYS.ACCESS_TOKEN, data.access)
           } else {
            await storage.clear()
            // localStorage
        }
        }
    } catch(error){
        return Promise.reject(err)
    }
}

export async function errorHandler(err: any) {
    try {
        const { status, data, statusText } = err.response

        if (Axios.isCancel(err)) {
            return Promise.reject()
        }

        if (status === 500) {
            return Promise.reject('Unable to complete request!')
        }

        let error = (data && data.detail) || Object.values(data).flat()[0] || statusText
        if (typeof error !== 'string') {
            error = 'Unable to complete request'
        }
        return Promise.reject(error)
    } catch (e) {
        return Promise.reject('Unable to complete request')
    }
}

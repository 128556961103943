import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";
import { useTranslation } from "react-i18next";
import { SectionKeys } from "../../utils/enums";
import { useTheme, useMediaQuery } from "@mui/material";
import { Menu } from "@mui/icons-material";
import { logoHeader } from "../../library/resources/images";
import { colors } from "../../resources/colors";

interface Props {
  window?: () => Window;
}

const drawerWidth = 240;

function HideOnScroll(props: Props & { children: React.ReactElement }) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const Header: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [activeSection, setActiveSection] = React.useState<string>(
    SectionKeys.initialSection
  );

  const handleDrawerToggle = () => {
    setMobileOpen((prev) => !prev);
  };

  const handleScrollToSection = (id: string) => {
    setActiveSection(id);
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const navItems = [
    { name: t("header.home"), keyToScroll: SectionKeys.initialSection },
    { name: t("header.aboutUs"), keyToScroll: SectionKeys.aboutUsSection },
    { name: t("header.eventLife"), keyToScroll: SectionKeys.eventsSection },
    { name: t("header.aboutApp"), keyToScroll: SectionKeys.aboutAppSection },
  ];

  const navItemStyles = (isActive: boolean) => ({
    color: isActive ? colors.darkGreen : colors.lightGreen,
    fontWeight: isActive ? "bold" : "300",
    textTransform: "none",
    fontSize: "1.2rem",
    fontFamily: "Montserrat",
    ":hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
      color: colors.darkGreen,
      fontWeight: "bold",
    },
  });

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center", marginTop: "64px" }}>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.keyToScroll} disablePadding>
            <ListItemButton
              sx={navItemStyles(activeSection === item.keyToScroll)}
              onClick={() => handleScrollToSection(item.keyToScroll)}
            >
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <HideOnScroll {...props}>
        <AppBar
          component="nav"
          sx={{
            backgroundColor: theme.palette.background.paper,
            height: "64px",
            boxShadow: "none",
            width: "100%",
            zIndex: theme.zIndex.drawer + 1,
          }}
        >
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              px: 2,
              padding: { xs: "0px 16px", md: "0px 160px" },
            }}
          >
            <Box
              component="img"
              src={logoHeader.src}
              alt="Logo"
              sx={{ height: { xs: 32, sm: 40 }, cursor: "pointer" }}
              onClick={() => handleScrollToSection(SectionKeys.initialSection)}
            />
            {isMobile ? (
              <IconButton
                color="inherit"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ display: { xs: "flex", sm: "none" } }}
              >
                <Menu sx={{ color: colors.darkGreen }} />
              </IconButton>
            ) : (
              <Box sx={{ display: "flex", gap: 2 }}>
                {navItems.map((item) => (
                  <Button
                    key={item.keyToScroll}
                    onClick={() => handleScrollToSection(item.keyToScroll)}
                    sx={navItemStyles(activeSection === item.keyToScroll)}
                  >
                    {item.name}
                  </Button>
                ))}
              </Box>
            )}
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};

export default Header;

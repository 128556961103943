import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { KEYS, storage } from "../utils/storage";

import en from "../locales/en.json";
import srLatin from "../locales/sr-Latn-RS.json";

const customLanguageDetector = {
  type: "languageDetector" as const,
  async: true,
  detect: (callback: (lang: string) => void) => {
    const savedLanguage = storage.load(KEYS.LANGUAGE);
    if (savedLanguage) {
      callback(savedLanguage);
    } else {
      const userLang = navigator.language || "sr-Latn-RS";
      callback(userLang);
    }
  },
  init: () => {},
  cacheUserLanguage: (lang: string) => {
    storage.save(KEYS.LANGUAGE, lang);
  },
};

i18n
  .use(customLanguageDetector)
  .use(initReactI18next)
  .init({
    lng: "sr-Latn-RS",
    resources: {
      en: { translation: en },
      "sr-Latn-RS": { translation: srLatin },
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
      transSupportBasicHtmlNodes: true, // Enable React rendering of basic tags
      transKeepBasicHtmlNodesFor: ["bold", "b", "strong"], // Allow tags like <b> and <bold>
    },
  });

export default i18n;

import { Box, Typography } from "@mui/material";

interface PersonStatementProps {
  personImg: string;
  nameImg: string;
  statementText: string;
}

const PersonStatement: React.FC<PersonStatementProps> = (props) => {
  const { personImg, nameImg, statementText } = props;

  return (
    <Box sx={styles.mainBox}>
      <Box sx={styles.nameImageWrapperBox}>
        <Box component="img" src={personImg} sx={styles.personImg} />
        <Box component="img" src={nameImg} sx={styles.nameImg} />
      </Box>
      <Box sx={styles.textWrapperBox}>
        <Typography sx={styles.text}>{statementText}</Typography>
      </Box>
    </Box>
  );
};

const styles = {
  mainBox: {
    display: "flex",
    flexDirection: "column",
    marginBottom: { xs: 4, md: 6 },
    alignItems: { xs: "center", md: "flex-start" },
  },
  nameImageWrapperBox: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    alignItems: "center",
    marginBottom: 2,
  },
  personImg: {
    marginRight: { md: 2 },
    marginBottom: { xs: 1, md: 0 },
    userSelect: "none",
    width: { xs: "80px", md: "100px" },
    height: { xs: "80px", md: "100px" },
    borderRadius: "50%",
  },
  nameImg: {
    userSelect: "none",
    maxWidth: { xs: "80%", md: "100%" },
  },
  textWrapperBox: {
    display: "flex",
    marginY: 2,
    textAlign: { xs: "left", md: "left" },
  },
  text: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: { xs: "0.875rem", md: "1rem" },
    lineHeight: "19.5px",
  },
};

export default PersonStatement;

export const KEYS = {
  ACCESS_TOKEN: "access",
  REFRESH_TOKEN: "refresh_token",
  SERVER: "server",
  LANGUAGE: "language",
  THEME: "theme",
};

export const storage = {
  save(key: string, value: string): void {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error("Error saving to localStorage:", error);
    }
  },

  load(key: string): string | null {
    try {
      const value = localStorage.getItem(key);
      return value ? JSON.parse(value) : null;
    } catch (error) {
      console.error("Error loading from localStorage:", error);
      return null;
    }
  },

  remove(key: string): void {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error("Error removing from localStorage:", error);
    }
  },

  clear(): void {
    try {
      localStorage.clear();
    } catch (error) {
      console.error("Error clearing localStorage:", error);
    }
  },
};

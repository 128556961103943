export enum SectionKeys {
    initialSection = "initial-section",
    aboutUsSection = "about-us-section",
    eventsSection = "events-section",
    aboutAppSection = "about-app-section",
}

export enum AppDetails {
    address = "Beograd",
    phoneNumber = "+381624725915",
    phoneDisplayNumber = "+381 62 4725915",
    email = "office@briniti.com",
    tikTok = "https://www.tiktok.com/@brinitiapp",
    instagram = "https://www.instagram.com/brini.ti/",
    linkedin = "https://www.linkedin.com/company/briniti/",
    youtube = "https://youtube.com/@briniti",
    linkToAppStore = "https://apps.apple.com/app/briniti/id1661280193",
    linkToPlayStore = "https://play.google.com/store/apps/details?id=com.briniti",
    observationLinkVideo = "https://youtu.be/GQf-vGo72wQ",
    checkupSchedulingVideo = 'https://youtu.be/JGPqjXim0XE',
    checkupReportVideo = 'https://youtu.be/dAt5MG68tus'
}
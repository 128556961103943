import { Base64 } from 'js-base64'

export enum KEYS {
    LANGUAGE = 'LANGUAGE_LOCALE',
    ACCESS_TOKEN = 'ACCESS_TOKEN',
    REFRESH_TOKEN = 'REFRESH_TOKEN',
    OWNER = 'OWNER'
}

class Storage {
    async save(key: KEYS, value: any){
        try {
            const encodedData = Base64.encode(JSON.stringify(value))
            await localStorage.setItem(key, encodedData)
        } catch (error) {
            await localStorage.removeItem(key)
        }
    }

    async load(key: KEYS): Promise<any | null> {
        try {
            const data = await localStorage.getItem(key)
            if(!data) {
                return null
            }

            const baseData = Base64.decode(data)
            return JSON.parse(baseData)
        } catch (error) {
            await localStorage.removeItem(key)
        }
    }
    
    async remove(key: KEYS) {
        try {
            await localStorage.removeItem(key)
        } catch (error) {
            console.error('storage', error)
        }
    }

    async clear() {
        await localStorage.clear()
    }
}

export const storage = new Storage()
import React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";

import Spacer from "../../../infrastructure/components/Spacer";
import { logo } from "../../../library/resources/images";

const SuccessReset = (): React.ReactElement => {
  return (
    <Container component="main" maxWidth="md">
      <Link
        href="/"
        sx={{ display: "flex", justifyContent: "center", paddingTop: "3rem" }}
      >
        <Box component="img" src={logo.src}></Box>
      </Link>

      <Spacer height={"15rem"} />
      <Typography component="h1" variant="h3" style={{ textAlign: "center" }}>
        Uspešno ste promenili lozinku!
      </Typography>
      <Typography
        component="h1"
        variant="h4"
        style={{ textAlign: "center", paddingTop: "3rem" }}
      >
        Nastavite sa korišćenjem mobilne aplikacije
      </Typography>
    </Container>
  );
};

export default SuccessReset;

export const logo = {
    src: require('../../infrastructure/img/logo.png')
}

export const logoHeader = {
    src: require('../../infrastructure/img/briniti_logo.svg').default
}

export const logo_dark = {
    src: require('../../infrastructure/img/logo_dark.png')
}

export const apps = {
    src: require('../../infrastructure/img/apps.jpg')
}

export const playstore = {
    src: require('../../infrastructure/img/playstore.svg').default
}

export const appstore = {
    src: require('../../infrastructure/img/appstore.svg').default
}

export const aboutUsSM = {
    src: require('../../infrastructure/img/aboutUsSM.png')
}

export const aboutUsMD = {
    src: require('../../infrastructure/img/aboutUsMD.png')
}

export const aboutUsLG = {
    src: require('../../infrastructure/img/aboutUsLG.png')
}
export const aboutUsXL = {
    src: require('../../infrastructure/img/aboutUsXL.png')
}

export const parallax = {
    src: require('../../infrastructure/img/parallax.png')
}

export const firstPhone = {
    src: require('../../infrastructure/img/sign_up_screen.png')
}

export const secondPhone = {
    src: require('../../infrastructure/img/institution_screen.png')
}

export const thirdPhone = {
    src: require('../../infrastructure/img/info_screen.png')
}

export const firstPhoneMobile = {
    src: require('../../infrastructure/img/sign_up_screen_mobile.png')
}

export const secondPhoneMobile = {
    src: require('../../infrastructure/img/institution_screen_mobile.png')
}

export const thirdPhoneMobile = {
    src: require('../../infrastructure/img/info_screen_mobile.png')
}


export const whiteBriniti = {
    src: require('../../infrastructure/img/white_briniti.svg').default
}

export const fourthPhoneMobile = {
    src:require('../../infrastructure/img/info_screen_mobile_CROPPED.png')
}


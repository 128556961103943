import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Spacer from "../infrastructure/components/Spacer";

import { logo } from "../library/resources/images";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "../i18n/LanguageSwitcher";
import { List, ListItem } from "@mui/material";

const useRightsBulletArray = [
  "termsOfUse.useRights.bulletList.copyrightNotices",
  "termsOfUse.useRights.bulletList.personalPurposes",
  "termsOfUse.useRights.bulletList.nonCommercialUse",
  "termsOfUse.useRights.bulletList.notModifiedInAnyWay",
  "termsOfUse.useRights.bulletList.illustrationsText",
];

const TermsPage = () => {
  const { t } = useTranslation();
  return (
    <Container component="main" maxWidth="md">
      <Box sx={{ display: "flex", justifyContent: "flex-end", paddingY: 2 }}>
        <LanguageSwitcher />
      </Box>
      <Spacer height={"2rem"} />
      <Link href="/" sx={{ display: "flex", justifyContent: "center" }}>
        <Box component="img" src={logo.src}></Box>
      </Link>
      <Spacer height={"4rem"} />

      <Typography variant="h3" sx={styles.pageTitle}>
        {t("termsOfUse.title").toUpperCase()}
      </Typography>
      <Spacer height={"4rem"} />
      <Stack spacing={3}>
        <Typography variant="h5" sx={styles.title}>
          {t("termsOfUse.generalTermsAndConditions.title")}
        </Typography>
        <Typography sx={styles.text}>
          {t("termsOfUse.generalTermsAndConditions.text")}
        </Typography>
        <Typography variant="h5" sx={styles.title}>
          {t("termsOfUse.useRights.title")}
        </Typography>
        <Typography sx={styles.text}>
          {t("termsOfUse.useRights.firstParagraph")}
        </Typography>
        <List sx={styles.list}>
          {useRightsBulletArray.map((i18nTag) => (
            <ListItem key={i18nTag} sx={styles.listItem}>
              {t(i18nTag)}
            </ListItem>
          ))}
        </List>
        <Typography variant="h5" sx={styles.title}>
          {t("termsOfUse.trademarksAndCopyrights.title")}
        </Typography>
        <Typography sx={styles.text}>
          {t("termsOfUse.trademarksAndCopyrights.text")}
        </Typography>
        <Typography variant="h5" sx={styles.title}>
          {t("termsOfUse.limitedLiability.title")}
        </Typography>
        <Typography sx={styles.text}>
          {t("termsOfUse.limitedLiability.text")}
        </Typography>
        <Typography variant="h5" sx={styles.title}>
          {t("termsOfUse.thirdPartyLinks.title")}
        </Typography>
        <Typography sx={styles.text}>
          {t("termsOfUse.thirdPartyLinks.text")}
        </Typography>
        <Typography variant="h5" sx={styles.title}>
          {t("termsOfUse.internationalUsers.title")}
        </Typography>
        <Typography sx={styles.text}>
          {t("termsOfUse.internationalUsers.text")}
        </Typography>
        <Typography variant="h5" sx={styles.title}>
          {t("termsOfUse.applicableLaw.title")}
        </Typography>
        <Typography sx={styles.text}>
          {t("termsOfUse.applicableLaw.text")}
        </Typography>
        <Typography variant="h5" sx={styles.title}>
          {t("termsOfUse.privacy.title")}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={styles.text}>
            {`${t("termsOfUse.privacy.text")} - `}
            <Link
              component={RouterLink}
              to={"/privacy-policy"}
              target="_blank"
              rel="noopener noreferrer"
              sx={styles.mailToLink}
            >
              {t("termsOfUse.privacy.privacyPolicyDocumentName")}
            </Link>
          </Typography>
        </Box>
        <Typography variant="h5" sx={styles.title}>
          {t("termsOfUse.contact.title")}
        </Typography>
        <Typography sx={styles.text}>
          {`${t("termsOfUse.contact.firstParagraph")}:`}
        </Typography>
      </Stack>
      <Typography sx={styles.text}>
        {t("termsOfUse.contact.companyName")}
      </Typography>
      <Typography sx={styles.text}>
        {`${t("termsOfUse.contact.phone")}: +381 65 4725915`}
      </Typography>
      <Typography sx={styles.text}>
        {`${t("termsOfUse.contact.email")}: `}
        <Link href="mailto:office@briniti.com" sx={styles.mailToLink}>
          office@briniti.com
        </Link>
      </Typography>
      <Typography sx={styles.text}>
        {t("termsOfUse.contact.secondParagraph")}
      </Typography>
      <Typography sx={styles.text}>
        {`${t("termsOfUse.contact.companyRegistrationNumber")}: 21785113`}
      </Typography>
      <Typography sx={styles.text}>{`${t(
        "termsOfUse.contact.taxIdentificationNumber"
      )}: 112997153`}</Typography>
      <Typography sx={styles.text}>
        {t("termsOfUse.contact.activityCode")}
      </Typography>
      <Spacer height={"2rem"} />
    </Container>
  );
};

const styles = {
  pageTitle: { textAlign: "center", wordWrap: "break-word" },
  title: { fontWeight: "bold" },
  text: { textAlign: "justify" },
  list: { listStyleType: "disc", listStylePosition: "inside" },
  listItem: { display: "list-item" },
  whatPersonalDataWeCollectText: { textAlign: "justify", marginY: "0.5rem" },
  mailToLink: { textDecoration: "none", lineHeight: 1.5 },
};

export default TermsPage;

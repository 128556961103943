import React, { useEffect } from "react";

const QRPage = (): React.ReactElement => {
  useEffect(() => {
    const redirectToOSBasedURL = () => {
      const userAgent = window.navigator.userAgent;
      let osURL;

      const isIOSSafari = /iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream;

      if (isIOSSafari) {
        osURL = "https://apps.apple.com/ca/app/briniti/id1661280193";
      } else if (/Android/.test(userAgent)) {
        osURL = "https://play.google.com/store/apps/details?id=com.briniti&hl=en";
      } else {
        osURL = "https://briniti.com/"; // Default URL or handle accordingly
      }

      window.location.href = osURL;
    };

    redirectToOSBasedURL();
  }, []);

  return (
    <div>
      <h1>Redirecting based on your OS...</h1>
    </div>
  );
};

export default QRPage;

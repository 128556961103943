import { Box, Typography } from "@mui/material";
import Person1Img from "../img/person_1.png";
import Person1Name from "../img/person_1_name.png";
import Person2Img from "../img/person_2_img.png";
import Person2Name from "../img/person_2_name.png";
import Person3Img from "../img/person_3_img.png";
import Person3Name from "../img/person_3_name.png";
import PersonStatement from "./PersonStatement";
import HandPhone from "../img/hand_holding_phone.png";
import { useTranslation } from "react-i18next";
import { colors } from '../../resources/colors';

const EventsForLife: React.FC = () => {
  const { t } = useTranslation();

  const personStatements = [
    {
      id: 1,
      personImg: Person1Img,
      nameImg: Person1Name,
      statementText: t("events.statements.statement01"),
    },
    {
      id: 2,
      personImg: Person2Img,
      nameImg: Person2Name,
      statementText: t("events.statements.statement02"),
    },
    {
      id: 3,
      personImg: Person3Img,
      nameImg: Person3Name,
      statementText: t("events.statements.statement03"),
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", lg: "row" },
        overflow: "hidden",
        alignItems: { xs: "center", lg: "flex-start" },
      }}
    >
      <Box sx={styles.mainBox}>
        <Box sx={styles.titleBox}>
          <Typography sx={styles.aboutUsTypography}>
            {t("events.eventsForLife")}
          </Typography>
          <Typography sx={styles.whoWeAreTypography}>
            {t("events.peopleSentence")}
          </Typography>
        </Box>
        <Box sx={styles.descriptionBox}>
          {personStatements.map((statement) => (
            <PersonStatement
              key={statement.id}
              personImg={statement.personImg}
              nameImg={statement.nameImg}
              statementText={statement.statementText}
            />
          ))}
        </Box>
      </Box>
      <Box
        component="img"
        src={HandPhone}
        sx={styles.handHoldingPhoneImg}
      />
    </Box>
  );
};

const styles = {
  mainBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    minHeight: {xs:'0', lg:'100vh'},
    paddingX: { xs: 2, md: 20 },
    paddingY: { xs: 2, md: 5 },
  },
  titleBox: { width: "100%", textAlign: { xs: "left", md: "left" } },
  aboutUsTypography: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: { xs: "1.4rem", md: "1.6rem" },
    lineHeight: { xs: "1.7rem", md: "1.9rem" },
    fontWeight: "300",
  },
  whoWeAreTypography: {
    fontFamily: "Inter",
    fontSize: { xs: "2rem", md: "3rem" },
    lineHeight: { xs: "2.5rem", md: "3.5rem" },
    fontWeight: "800",
    color: colors.lightGreen,
  },
  descriptionBox: { marginTop: 2 },
  handHoldingPhoneImg: {
    userSelect: "none",
    width: { xs: "100%", md: "auto" },
    alignSelf: { xs: "center", lg: "flex-start" },
    marginTop: {xs: '0px', lg:'127px'}
  },
};

export default EventsForLife;

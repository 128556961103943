import React from 'react';

import Container from '@mui/material/Container';

import Spacer from '../infrastructure/components/Spacer';
import NewPasswordForm from './forms/NewPasswordForm';

import { Box } from '@mui/material';



const NewPasswordPage = () => {

    return (
        <Box sx={{
                height:'100vh',
                background:{
                    xs:'linear-gradient(145deg, rgba(255,255,255,1) 15%, rgba(88,177,173,1) 48%, rgba(22,84,99,1) 100%);',
                    md:'linear-gradient(171.22deg, #FFFFFF 3.42%, #58B1AD 43.47%, #165463 102.53%)'
                },
                display:'flex',
                flexDirection:'column',
                justifyContent:'center',
                alignItems:'center'}}>
            <Container component='main' maxWidth='md'>
            <Spacer height={'1rem'} />
                <NewPasswordForm />
            </Container>
        </Box>
    );
}

export default NewPasswordPage;

import { Box, Typography, Link } from "@mui/material";
import { appstore, playstore } from "../../library/resources/images";
import { useTranslation } from "react-i18next";
import { AppDetails } from "../../utils/enums";
import VideoSegment from "./VideoSegment";

const AboutAppSection: React.FC = () => {
  const { t } = useTranslation();

  const embedVideos = [
    {
      id: 1,
      title: t("aboutApp.addNote"),
      videoUrl: AppDetails.observationLinkVideo,
    },
    {
      id: 2,
      title: t("aboutApp.checkupScheduling"),
      videoUrl: AppDetails.checkupSchedulingVideo,
    },
    {
      id: 3,
      title: t("aboutApp.addCheckupReport"),
      videoUrl: AppDetails.checkupReportVideo,
    },
  ];

  return (
    <Box sx={styles.mainBox}>
      <Box sx={styles.titleBox}>
        <Typography sx={styles.aboutUsTypography}>
          {t("aboutApp.title")}
        </Typography>
        {embedVideos.map((video) => (
          <VideoSegment key={video.id} title={video.title} videoUrl={video.videoUrl} />
        ))}
      </Box>
      <Box sx={styles.storeLinks}>
          <Link
            href={AppDetails.linkToAppStore}
            target="_blank"
            rel="noreferrer"
            sx={styles.storeLink}
          >
            <Box
              component="img"
              src={appstore.src}
              alt="App Store"
              sx={styles.storeImage}
            />
          </Link>
          <Link
            href={AppDetails.linkToPlayStore}
            target="_blank"
            rel="noreferrer"
            sx={styles.storeLink}
          >
            <Box
              component="img"
              src={playstore.src}
              alt="Google Play"
              sx={styles.storeImage}
            />
          </Link>
        </Box>
    </Box>
  );
};

const styles = {
  mainBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: {xs: "flex-start", md: "space-evenly"},
    minHeight: {xs: "50vh", md: "100vh"},
    paddingX: { xs: 2, md: 20 },
    paddingTop: 0,
    paddingBottom:{xs:10, md: 5},
    marginBottom:{xs:2},
    alignItems: { xs: "center", md: "center" },
  },
  titleBox: {
    width: "100%",
    textAlign: { xs: "left", md: "left" },
  },
  aboutUsTypography: {
    fontFamily: "Montserrat, sans-serif",
    fontSize: { xs: "1.4rem", md: "1.6rem" },
    lineHeight: { xs: "1.7rem", md: "1.9rem" },
    fontWeight: "300",
  },
  storeLinks: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    mt: { xs: 2, md: 4 },
    gap: { xs: 1, md: 3 },
    flexWrap: "wrap",
  },
  storeLink: {
    display: "inline-block",
    textAlign: "center",
  },
  storeImage: {
    maxWidth: { xs: "150px", md: "300px" },
    height: "auto",
  },
};

export default AboutAppSection;

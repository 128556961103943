import React, { useEffect, useState } from "react";
import { Box, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";
import { storage, KEYS } from "../utils/storage";
import { locales } from "./locales.constants";

interface LanguageSwitcherProps {
  defaultLanguage?: string
}

const LanguageSwitcher: React.FC<LanguageSwitcherProps> = (props) => {
  const {defaultLanguage} = props
  const { i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState<string>(defaultLanguage ?? i18n.language);

  useEffect(() => {
    const loadLanguage = async () => {
      const savedLanguage = await storage.load(KEYS.LANGUAGE);
      if (savedLanguage) {
        i18n.changeLanguage(savedLanguage);
        setCurrentLanguage(savedLanguage);
      }
    };
    loadLanguage();
  }, [i18n]);

  const handleChangeLanguage = async (event: SelectChangeEvent) => {
    const language = event.target.value as string;
    i18n.changeLanguage(language);
    setCurrentLanguage(language);
    await storage.save(KEYS.LANGUAGE, language);
  };

  return (
    <Box>
      <Select
        value={currentLanguage}
        onChange={handleChangeLanguage}
        displayEmpty
        disableUnderline={true}
        renderValue={(selected) => locales[selected as string]?.title}
      >
        {Object.keys(locales).map((locale) => (
          <MenuItem key={locale} value={locale}>
            {locales[locale]?.title}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default LanguageSwitcher;
